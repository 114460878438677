import React, { useEffect, useState } from "react";
import { graphql, StaticQuery } from "gatsby"
import PropTypes from "prop-types";

import Layout from "../components/layout";
import SEO from "../components/seo";

import SmallHero from '../components/elements/small-hero/small-hero';
import BookCta from '../components/elements/book-cta/book-cta';
import TwoColFullScreen from "../components/layouts/2-col-full-screen/2-col-full-screen";
import ServiceInfo from "../components/services/service-info/service-info";
import SubIntro from "../components/elements/sub-intro/sub-intro";

const VIPListPage = ({ data }) => {

  let [loaded, setLoaded] = useState(true)

  useEffect(() => {
    setLoaded(true)
  }, [])

  return (
    <Layout>
      <SEO
        title="VIP List"
      />
      <SmallHero 
        loaded={loaded}
        bgImage={data.herofull.childImageSharp.fluid}
        title='VIP List'
      />
      <TwoColFullScreen cols={[{
          bg: 'text-white bg-primary-darker',
          content: <ServiceInfo 
              key={1}
              title="Silver"
              priceList={[
                {
                  price: '£299',
                  text: 'per year (£24.91)',
                  padding: 'pr-6'
                }
              ]}
              smallPrice
              alignPrice="bottom"
              lowerText={<div>
                  <p>
                  5% off treatments, retail products and drinks menu <br/>  
                  Complimentary Birthday nails and a wash & blow dry <br/>  
                  30 min Forma Treatment <br/>  
                  Goody Bag worth £50
                  </p>
                </div>
              }
            />
        },
        {
          bg: 'bg-white',
          content: <ServiceInfo 
              key={2}
              title="Gold"
              priceList={[
                {
                  price: '£499',
                  text: 'per year (£41.66)',
                  padding: 'pr-6'
                }
              ]}
              smallPrice
              alignPrice="bottom"
              lowerText={<div>
                  <p>
                  10% off treatments, retail products and drinks menu<br/>  
                  Complimentary Birthdays nails, a wash & blow dry, and luxury spa pedicure<br/>  
                  30 min Forma Treatment<br/>  
                  Goody Bag worth £75
                  </p>
                </div>
              }
            />
        },
        {
          bg: 'bg-primary-darker text-white md:text-black md:bg-white',
          content: <ServiceInfo 
              key={3}
              title="Platinum"
              priceList={[
                {
                  price: '£799',
                  text: 'per year (£66.58)',
                  padding: 'pr-6'
                }
              ]}
              smallPrice
              alignPrice="bottom"
              lowerText={<div>
                  <p>
                  10% off treatments <br/>  
                  Complimentary Birthdays nails, a wash & blow dry, and luxury spa pedicure  <br/>  
                  Dedicated members emails <br/>  
                  Priority Reservation in champagne lounge for up to 4 people <br/>
                  Priority booking for peak periods i.e. Christmas <br/>
                  Welcome goody bag worth £100 <br/>
                  Free hair treatment upgrade with every colour service (i.e. Fusio Dose or Olaplex) 
                  </p>
                </div>
              }
            />
        },
        {
          bg: 'bg-white md:text-white md:bg-primary-darker',
          content: <ServiceInfo 
          key={3}
          title="DIAMOND VIP PACKAGE"
          priceList={[
            {
              price: 'COMING SOON ',
              padding: 'pr-6'
            }
          ]}
          smallPrice
          alignPrice="bottom"
          lowerText={<div>
              <p>
              Upgrade will be available to clients who have already purchased a membership<br/>  
              </p>
            </div>
          }
        />
        } ]}/>
        <SubIntro 
        text={<div className="mt-20 md:mt-24">
          <h2 className="mb-8 text-3xl">T’s and C’s</h2>
          <p>
          ‘birthday’ items may be taken at anytime in the clients birthday month <br/>
          Clients must be over 18 for alcoholic drinks menu items <br/>
          No cash alternative or refunds <br/>
          Treatments must be taken by the membership holder only <br/>
          Retail products exclude sale items
          </p>
        </div>}
      />
      <BookCta discount="https://gift-cards.phorest.com/salons/houseofpureltd" link="https://phorest.com/book/salons/houseofpureltd" phone="01353 661 321" email="elybeautyandskinclinic@gmail.com" bg={data.bookbg.childImageSharp.fluid} />
    </Layout>
  );
}

VIPListPage.propTypes = {
  data: PropTypes.object,
};

const indexQuery = graphql`
  query {
    herofull: file(relativePath: { eq: "main-bg.jpg" }) {
      childImageSharp {
        fluid(quality: 50, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    bookbg: file(relativePath: { eq: "booking-bg-blurry.jpg" }) {
      childImageSharp {
        fluid(quality: 50, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
const VIPListComponent = props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <VIPListPage props data={data} {...props} />
    )}
  />
)

VIPListComponent.displayName = "VIPListComponent"

export default VIPListComponent
